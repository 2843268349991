import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import toast from 'react-hot-toast';
import './../../App.css'

const App = ({ placeholder }) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [claimId, setClaimId] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileHandle, setFileHandle] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const authToken = queryParams.get('authToken');
    const claimId = queryParams.get('claimId');
    const templateId = queryParams.get('templateId');

    setAuthToken(authToken);
    setClaimId(claimId);
    setTemplateId(templateId);

    const verifyUser = async () => {
      try {
        const res = await axios.post('/verifyUser', { token: authToken, claim_id: claimId });
        const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000;
        const verificationData = {
          data: res.data.user.data,
          token: authToken,
          expiry: expirationTime,
        };
        sessionStorage.setItem('verificationData', JSON.stringify(verificationData));
      } catch (error) {
        console.error('Verification failed:', error);
      }
    };

    const checkSessionStorage = () => {
      const storedData = sessionStorage.getItem('verificationData');
      if (storedData) {
        const { data, expiry } = JSON.parse(storedData);
        if (new Date().getTime() < expiry) {
          console.log('Using cached verification data:', data);
          return true;
        } else {
          sessionStorage.removeItem('verificationData');
        }
      }
      return false;
    };

    if (authToken && !checkSessionStorage()) {
      verifyUser();
    }
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (templateId) {
        try {
          const response = await axios.get(`/api/v1/template?templateId=${templateId}`);
          const template = response.data.data.template;
          console.log('Fetched template:', template);
          setTemplateContent(template);
        } catch (error) {
          console.error('Failed to fetch template:', error);
          toast.error('Failed to fetch template');
        }
      }
    };

    fetchTemplate();
  }, [templateId]);

  useEffect(() => {
    if (authToken && claimId) {
      getPlaceHolders(authToken, claimId);
    }
  }, [templateContent]);

  useEffect(() => {
    if (templateContent) {
      setContent(templateContent);
    }
  }, [templateContent]);

  const getPlaceHolders = async (token, claimId) => {
    const loadingToastId = toast.loading('Please Wait...');
    try {
      const response = await axios.get(`/variables?claim_id=${claimId}&token=${token}`);
      console.log('API call successful:', response.data);
      const placeholders = response.data.claim.data;

      const additionalPlaceholders = {
        claim_id: claimId,
      };

      const updatedContent = replacePlaceholders(
        templateContent,
        placeholders,
        additionalPlaceholders
      );

      toast.dismiss(loadingToastId);
      toast.success('Your template is ready.');
      setContent(updatedContent);
    } catch (error) {
      toast.dismiss(loadingToastId);
      toast.error('API call failed');
      console.error('API call failed:', error);
    }
  };

  const replacePlaceholders = (template, placeholders, additionalPlaceholders = {}) => {
    let updatedTemplate = template;
    const allPlaceholders = { ...placeholders, ...additionalPlaceholders };
    Object.keys(allPlaceholders).forEach((key) => {
      const value = allPlaceholders[key] != null ? String(allPlaceholders[key]) : '';
      const valueWithBreaks = value.replace(/\n/g, '<br>');
      const regex = new RegExp(`{{${key}}}`, 'g');
      updatedTemplate = updatedTemplate.replace(regex, valueWithBreaks);
  });
    return updatedTemplate;
  };

  const handleSave = async () => {
    if (fileHandle) {
      try {
        const writableStream = await fileHandle.createWritable();
        await writableStream.write(editor.current.value);
        await writableStream.close();
        toast.success('File saved successfully.');
      } catch (error) {
        console.error('Save failed:', error);
        toast.error('Save failed.');
      }
    } else {
      handleSaveAs();
    }
  };

  const handleSaveAs = async () => {
    try {
      const newFileHandle = await window.showSaveFilePicker({
        suggestedName: fileName || 'document.txt',
        types: [
          {
            description: 'Text Files',
            accept: {
              'text/plain': ['.txt'],
            },
          },
        ],
      });
      const writableStream = await newFileHandle.createWritable();
      await writableStream.write(editor.current.value);
      await writableStream.close();
      setFileHandle(newFileHandle);
      setFileName(newFileHandle.name);
      toast.success('File saved successfully.');
    } catch (error) {
      console.error('Save As failed:', error);
      toast.error('Save As failed.');
    }
  };
  const handleFileUpload = async () => {
    try {
      const fileHandle = await window.showOpenFilePicker({
        types: [
          {
            description: 'Text Files',
            accept: {
              'text/plain': ['.txt'],
            },
          },
        ],
      });

      const file = await fileHandle[0].getFile();
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setContent(content);
        setFileName(file.name);
        setFileHandle(fileHandle[0]);
      };

      reader.readAsText(file);
    } catch (error) {
      console.error('File open failed:', error);
      toast.error('File open failed.');
    }
  };

  let recognition;
  let recognizing = false;
  let finalTranscript = '';  // Store finalized transcript
  
  // Map of spoken words to punctuation marks
  const punctuationMap = {
    'full stop': '.',
    'comma': ',',
    'question mark': '?',
    'exclamation mark': '!',
  };
  
  // Function to replace spoken punctuation with actual punctuation marks
  const replaceSpokenPunctuation = (text) => {
    Object.keys(punctuationMap).forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      text = text.replace(regex, punctuationMap[word]);
    });
    return text;
  };
  
  // Initialize SpeechRecognition
  const initSpeechRecognition = () => {
    if (!recognition) {
      recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.continuous = true;  
      recognition.interimResults = true;  
  
      recognition.onresult = (event) => {
        let interimTranscript = '';  
  
        for (let i = event.resultIndex; i < event.results.length; i++) {
          let transcript = event.results[i][0].transcript;
          
          // Replace spoken punctuation
          transcript = replaceSpokenPunctuation(transcript);
  
          if (event.results[i].isFinal) {
            finalTranscript += transcript;  
          } else {
            interimTranscript += transcript;  
          }
        }
  
        // Update the content in the editor
        setContent(finalTranscript + ' ' + interimTranscript); 
      };
  
      recognition.onstart = () => {
        recognizing = true;
        console.log("Speech recognition started 🚀");
      };
  
      recognition.onend = () => {
        recognizing = false;
        console.log("Speech recognition ended 🛑");
        handleStopRecognition(); 
      };
  
      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };
    }
  };
  
  // Function to start recognition
  const startSpeechRecognition = () => {
    initSpeechRecognition();
    if (!recognizing) {
      recognition.start();
      console.log("Speech recognition manually started.");
    } else {
      console.warn("Speech recognition is already running 🤔");
    }
  };
  
  // Function to stop recognition
  const stopSpeechRecognition = () => {
    if (recognizing) {
      recognition.stop();
      recognizing = false;
      console.log("Speech recognition manually stopped 🛑");
    }
  };
  
  // Function to handle confirmation for stopping speech recognition
  const handleStopRecognition = () => {
    const stop = window.confirm("Do you want to stop speech recognition?");
    if (stop) {
      stopSpeechRecognition();  
    } else {
      // If user cancels, restart speech recognition after a short delay
      setTimeout(() => {
        if (!recognizing) {
          startSpeechRecognition();
        }
      }, 1000); 
    }
  };
  

  const joditConfig = {
    buttons: [
      'undo',
      'redo',
      '|',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'align',
      '|',
      'ul',
      'ol',
      'outdent',
      'indent',
      '|',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'link',
      'table',
      '|',
      'hr',
      'eraser',
      'copyformat',
      '|',
      'fullsize',
      'selectall',
      'print',
      '|',
      'source',
      '|',
      {
        name: 'speechRecognize',
        exec: () => startSpeechRecognition(),
      },
      {
        name: 'Open File',
        exec: handleFileUpload,
      },
      {
        name: 'Save ',
        exec: handleSave,
      },
      {
        name: 'Save As',
        exec: handleSaveAs,
      },
      {
        name: 'Clear',
        exec: () => {
          setContent('');
          setFileName('');
          setFileHandle(null);
        },
      },
    ],
    minHeight: 600,
  };

  return (
    <div className="main-container">
      <div className="editor-container">
        {fileName && (
          <div className="file-name-container">
            <p className="file-name">File Name: {fileName}</p>
          </div>
        )}
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}
          onBlur={(newContent) => setContent(newContent)}
          onChange={(newContent) => {}}
          config={joditConfig}
          className="editor"
        />
      </div>
    </div>
  );
};

export default App;
